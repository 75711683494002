<template>
    <div>

    <!-- HEADER -->
    <header class="header">
        <div class="topbar">
            <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                <div class="d-block d-md-flex align-items-center text-center">
                    <div class="mr-3 d-inline-block">
                    <a href="tel:6285740177785"><i class="fa fa-phone mr-2 fa fa-flip-horizontal"></i>+62 857-4017-7785 </a>
                    </div>
                    <div class="mr-auto d-inline-block">
                    </div>
                    <!-- MEDSOS -->
                    <a class="mr-2" href="https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+di+Hasina,+mohon+dibantu" target="_blank">
                        <img width="17" :src="getImage('assets/icon/wa.png')" >
                    </a>
                    <a class="mr-2" href="https://www.youtube.com/@HelloHasina" target="_blank">
                        <img width="19" :src="getImage('assets/icon/yt.png')" >
                    </a>
                    <div class="dropdown d-inline-block pl-md-0">
                        <a class="dropdown-toggle" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img width="17" :src="getImage('assets/icon/ig.png')" ><i class="fas fa-chevron-down pl-2"></i>
                        </a>
                        <div class="dropdown-menu mt-0" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" target="_blank" href="https://www.instagram.com/hasinamerch/"><img width="17" :src="getImage('assets/icon/ig.png')" > &nbsp; Hasina Merch</a>
                            <a class="dropdown-item" target="_blank" href="https://www.instagram.com/hasina.official/"><img width="17" :src="getImage('assets/icon/ig.png')" > &nbsp; Hasina Official</a>
                            <a class="dropdown-item" target="_blank" href="https://www.instagram.com/hasinabatik/"><img width="17" :src="getImage('assets/icon/ig.png')" > &nbsp; Hasina Batik</a>
                        </div>
                    </div>
                    <!-- MEDSOS -->
                    <div class="dropdown d-inline-block pl-2 pl-md-0">
                        <a class="dropdown-toggle" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img v-if="translateAktif == 'id'" width="20" :src="getImage('assets/icon/id.png')" >
                            <img v-if="translateAktif == 'en'" width="20" :src="getImage('assets/icon/en.png')" >
                            <i class="fas fa-chevron-down pl-2"></i>
                        </a>
                        <div class="dropdown-menu mt-0" aria-labelledby="dropdownMenuButton">
                            <a  class="dropdown-item" @click="updateTranslateAktif('id')" style="cursor:pointer"><img width="20" :src="getImage('assets/icon/id.png')" > &nbsp; Indonesia</a>
                            <a  class="dropdown-item" @click="updateTranslateAktif('en')" style="cursor:pointer"><img width="20" :src="getImage('assets/icon/en.png')" > &nbsp; English</a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <nav class="navbar navbar-light bg-white navbar-static-top navbar-expand-lg header-sticky">
            <div class="container-fluid">
                <button type="button" class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse"><i class="fas fa-align-left"></i></button>
                <a class="navbar-brand" href="#">
                    <img class="img-fluid" :src="getImage('assets/icon/logo.png')" alt="logo">
                </a>
                <div class="navbar-collapse collapse justify-content-center">
                    <ul class="nav navbar-nav">
                        <li class="nav-item dropdown">
                            <a href="/" class="nav-link">{{ translateAktif == 'id' ? 'Beranda' : 'Home'}}</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ translateAktif == 'id' ? 'Tentang Kami' : 'About'}} <i class="fas fa-chevron-down fa-xs"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <router-link to="/about_us" class="dropdown-item">{{ translateAktif == 'id' ? 'Tentang Kami' : 'About us'}}</router-link>
                                </li>
                                <li>
                                    <router-link to="/why_us" class="dropdown-item">{{ translateAktif == 'id' ? 'Mengapa Kami' : 'Why Us'}}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{ translateAktif == 'id' ? 'Pakaian' : 'Chothing'}} <i class="fas fa-chevron-down fa-xs"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">{{ translateAktif == 'id' ? 'Kaos' : 'T-Shirt'}} <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV01" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">{{ translateAktif == 'id' ? 'Kameja Polo' : 'Polo Shirt'}} <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV02" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">{{ translateAktif == 'id' ? 'Kameja' : 'Shirt'}} <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV03" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">{{ translateAktif == 'id' ? 'Jaket' : 'Jacket'}} <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV04" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">Hoodie <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV05" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">Training <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV06" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">{{ translateAktif == 'id' ? 'Topi' : 'Hat'}} <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/portofolio/KNV07" class="dropdown-item">Portofolio</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Batik <i class="fas fa-chevron-down fa-xs"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item dropdown-toggle" href="#">Custom <i class="fas fa-chevron-right fa-xs"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-submenu">
                                            <a class="dropdown-item dropdown-toggle" href="#">Batik Tulis<i class="fas fa-chevron-right fa-xs"></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <router-link to="/portofolio/BTK01" class="dropdown-item">Portofolio</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown-submenu">
                                            <a class="dropdown-item dropdown-toggle" href="#">Batik Cap <i class="fas fa-chevron-right fa-xs"></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <router-link to="/portofolio/BTK02" class="dropdown-item">Portofolio</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown-submenu">
                                            <a class="dropdown-item dropdown-toggle" href="#">Batik Printing <i class="fas fa-chevron-right fa-xs"></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <router-link to="/portofolio/BTK03" class="dropdown-item">Portofolio</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown-submenu">
                                            <a class="dropdown-item dropdown-toggle" href="#">Batik Digital Printing <i class="fas fa-chevron-right fa-xs"></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <router-link to="/portofolio/BTK04" class="dropdown-item">Portofolio</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown-submenu">
                                    <a class="dropdown-item" target="_blank" href="https://www.instagram.com/hasinabatik/">{{ translateAktif == 'id' ? 'Stok Tersedia' : 'Ready Stock'}} 
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link to="/portofolio/MRC01" class="nav-link">Merchandise</router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link to="/contact" class="nav-link">{{ translateAktif == 'id' ? 'Kontak Kami' : 'Contact Us'}} </router-link>
                        </li>
                        <li class="nav-item dropdown">
                            <router-link to="/order" class="nav-link">{{ translateAktif == 'id' ? 'Cara Order' : 'How to Order'}}</router-link>
                        </li>
                    </ul>
                </div>
                <div class="add-listing d-none d-sm-block">
                <!-- <a class="btn btn-primary btn-md" href="submit-property.html"> <i class="fa fa-whatsapp"></i> </a> -->
                </div>
            </div>
        </nav>
    </header>
    <!-- HEADER -->
    <div :style="currentRouteName == 'contact' ? 'display:none' : 'display:block'"  class="whatsapp-button">
        <a target="_blank" href="https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+di+Hasina,+mohon+dibantu"><i  class="fa fa-whatsapp"></i></a>
    </div>

    <slot :translateAktif="translateAktif" :updateTranslateAktif="updateTranslateAktif"></slot>

    <!-- FOOTER -->

    <footer class="footer bg-dark space-pt">
    <div class="container">
    <div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="footer-contact-info">
        <h5 class="mb-4" style="color:#b2cc45">Profil Hasina</h5>
        <!-- <p class="text-white mb-4">"Berisi tentang tagline atau kutipan yang menggambarkan hasina"</p> -->
        <ul class="list-unstyled mb-0">
        <li>
        <b>
            <i style="color:#b2cc45" class="fas fa-map-marker-alt"></i>
        </b>
        <span>Grosir Batik Setono, Blok Sejahtera No 24, Kota Pekalongan</span>
        </li>
        <li>
        <b>
            <i style="color:#b2cc45" class="fas fa-map-marker-alt"></i>
        </b>
        <span>Cibeunying Kidul, Kota Bandung</span>
        </li>
        <li>
        <b>
            <i style="color:#b2cc45" class="fas fa-microphone-alt"></i>
        </b>
        <span>+62 857-4017-7785 </span>
        </li>
        <li>
        <b>
            <i style="color:#b2cc45" class="fas fa-headset"></i>
        </b>
        <span>hasinahello@gmail.com</span>
        </li>
        </ul>
        </div>
    </div>
    <div class="hide-on-mobile col-lg-3 col-md-6 mt-4 mt-md-0">
        <div class="footer-link">
        <h5 class=" mb-4" style="color:#b2cc45">{{ translateAktif == 'id' ? 'Pakaian' : 'Chothing'}}</h5>
        <ul class="list-unstyled mb-0">
        <li>
            <router-link to="/portofolio/KNV01">{{ translateAktif == 'id' ? 'Kaos' : 'T-Shirt'}}</router-link>
        </li>
        <li>
            <router-link to="/portofolio/KNV02">{{ translateAktif == 'id' ? 'Kameja Polo' : 'Polo Shirt'}}</router-link>
        </li>
        <li>
            <router-link to="/portofolio/KNV03">{{ translateAktif == 'id' ? 'Kameja' : 'Shirt'}}</router-link>
        </li>
        <li>
            <router-link to="/portofolio/KNV04">{{ translateAktif == 'id' ? 'Jaket' : 'Jacket'}}</router-link>
        </li>
        </ul>
        <ul class="list-unstyled mb-0">
        <li>
            <router-link to="/portofolio/KNV05">Hoodie</router-link>
        </li>
        <li>
            <router-link to="/portofolio/KNV06">Training</router-link>
        </li>
        <li>
            <router-link to="/portofolio/KNV07">{{ translateAktif == 'id' ? 'Topi' : 'Hat'}}</router-link>
        </li>
        <li>
        &nbsp;
        </li>
        </ul>
        </div>
    </div>
    <div class="hide-on-mobile col-lg-3 col-md-6 mt-4 mt-md-0">
        <div class="footer-link">
        <h5 class=" mb-4" style="color:#b2cc45">Batik</h5>
        <ul class="list-unstyled mb-0">
        <li>
            <router-link to="/portofolio/BTK01">Batik Tulis</router-link>
        </li>
        <li>
            <router-link to="/portofolio/BTK02">Batik Cap</router-link>
        </li>
        </ul>
        <ul class="list-unstyled mb-0">
        <li>
            <router-link to="/portofolio/BTK03">Batik Printing</router-link>
        </li>
        <li>
            <router-link to="/portofolio/BTK04">Batik Digital Printing</router-link>
        </li>
        </ul>
        </div>
    </div>
    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
        <div class="footer-contact-info">
        <h5 class=" mb-4" style="color:#b2cc45">Follow Us</h5>
        <ul class="list-unstyled mb-0">
        <li>
        <a target="_blank" href="https://www.instagram.com/hasinamerch/">
            <i style="color:#b2cc45" class="fab fa-instagram"></i>
        <span>@hasinamerch</span>
        </a>
        </li>
        <li>
        <a target="_blank" href="https://www.instagram.com/hasina.official/">
            <i style="color:#b2cc45" class="fab fa-instagram"></i>
        <span>@hasina.official</span>
        </a>
        </li>
        <li>
        <a target="_blank" href="https://www.instagram.com/hasinabatik/">
            <i style="color:#b2cc45" class="pb-1 fab fa-instagram"></i>
        <span>@hasinabatik</span>
        </a>
        </li>
        </ul>
        </div>
    </div>
    </div>
    </div>
    <div class="footer-bottom">
    <div class="container">
    <div class="row align-items-center">
        <div class="col-md-4 text-center text-md-left">
        <a href="index.html">
        <img class="img-fluid footer-logo" src="images/logo-light.svg" alt="">
        </a>
        </div>
        <div class="col-md-4 text-center my-3 mt-md-0 mb-md-0">
        <a id="back-to-top" class="back-to-top" href="#">
        <i class="fas fa-angle-double-up"></i>
        </a>
        </div>
        <div class="col-md-4 text-center text-md-right">
        <p class="mb-0 text-white"> &copy;Copyright <span id="copyright">
        </span>
        <a href="#"> Hasina </a> 2023
        </p>
        </div>
    </div>
    </div>
    </div>
    </footer>
    <!-- FOOTER -->
    </div>
</template>
<style>
    .whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    }

    .whatsapp-button a {
    display: inline-block;
    background-color: #25d366;
    color: white;
    padding: 5px 10px;
    border-radius: 50%;
    text-decoration: none;
    }

    .whatsapp-button a::before {
    content: "Halo Hasina!!!";
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    }

    /* Efek tooltip muncul saat hover */
    .whatsapp-button a:hover::before {
    opacity: 1;
    visibility: visible;
    }
</style>
<script>
    export default {
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Home',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        mounted() {
            this.currentRouteName;
            this.getLocalStorage();
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        data() {
            return {
                translateAktif: this.$root.$translateAktif
            }
        },
        props: {
            dataProp: String
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            updateTranslateAktif(newValue) {
                this.$root.$translateAktif = newValue;
                this.translateAktif = newValue;
                localStorage.setItem('translate', newValue);
                location.reload();
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
                if(this.translateAktif === '' || this.translateAktif === undefined){
                    this.translateAktif =  this.$root.$translateAktif;
                }
            }

        }
    }
</script>