<template>
    <!-- CONTENT ================================================== -->
    <div>


<section class="hide-on-mobile clearfix">
  <div id="slider" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#slider" data-slide-to="0" class="active"></li>
      <li data-target="#slider" data-slide-to="1"></li>
      <li data-target="#slider" data-slide-to="2"></li>
      <li data-target="#slider" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="img-fluid" :src="getImage('assets/produk/4.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-10">
                  <!-- <span class="text-white animated-07">Konveksi Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Baju baru, semangat baru</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="img-fluid" :src="getImage('assets/produk/1.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-10">
                  <!-- <span class="text-white animated-07">Konveksi Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Baju baru, semangat baru</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="img-fluid" :src="getImage('assets/produk/2.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-10">
                  <!-- <span class="text-white animated-07">Batik Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Tampil keren, dengan batik modern</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="img-fluid" :src="getImage('assets/produk/3.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-10">
                  <!-- <span class="text-white animated-07">Batik Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Tampil keren, dengan batik modern</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <a class="carousel-control-prev" href="#slider" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#slider" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
  </div>
  </div>
</section>

<section class="hide-on-desktop clearfix">
  <div id="slider1" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#slider1" data-slide-to="0" class="active"></li>
      <li data-target="#slider1" data-slide-to="1"></li>
      <li data-target="#slider1" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="img-fluid" :src="getImage('assets/produk/mb-1.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-10">
                  <!-- <span class="text-white animated-07">Konveksi Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Baju baru, semangat baru</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="img-fluid" :src="getImage('assets/produk/mb-2.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10">
                  <!-- <span class="text-white animated-07">Batik Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Tampil keren, dengan batik modern</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="img-fluid" :src="getImage('assets/produk/mb-3.webp')" alt="">
        <div class="slider-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10">
                  <!-- <span class="text-white animated-07">Batik Hasina</span>
                  <h1 class="text-white mb-3 animated-08">Tampil keren, dengan batik modern</h1> -->
                  <!-- <a href="#" class="btn btn-link animated-08">View more <i class="fas fa-arrow-right pl-2"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <a class="carousel-control-prev" href="#slider1" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#slider1" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
  </div>
  </div>
</section>

<section class="space-pb mt-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title text-center">
          <h2>{{ translateAktif == 'id' ? 'Tentang Kami' : 'About Us'}}</h2>
          <p v-if="translateAktif == 'id'">Selamat datang di Hasina, Hasina adalah usaha kami di bidang batik dan konveksi, 
                kami sudah berdiri sejak tahun 2000, dan brand Hasina juga sudah resmi terdaftar 
                di Indonesia. Kami memproduksi beberapa bahan kain batik, seperti batik tulis, 
                batik cap, batik printing dan digital printing, batik costum. Kami juga 
                menyediakan jasa pembuatan seragam kantor, seragam sekolah SD, SMP, SMA bahkan TK, 
                Jas, Jaket, Sweater, Kemeja, kaos oblong, kaos polo, hingga dapat produksi tas dan 
                menyediakan alat tulis kantor</p>
          <p v-if="translateAktif == 'en'">Welcome to Hasina, Hasina is our business in the field of batik and convection, we have 
              been established since 2000, and the Hasina brand has also been officially registered 
              in Indonesia. We produce several batik fabric materials, such as written batik, stamped batik, 
              printed and digital printed batik, custom batik. We also provide services for making office 
              uniforms, elementary school, middle school, high school and even kindergarten uniforms, jackets,
                jackets, sweaters, shirts, T-shirts, polo shirts, we can even produce bags and provide office 
                stationery.</p>

          <p style="color:black" v-if="translateAktif == 'id'">Hasina juga menerima pesanan berbagai barang custom dan PO</p>
          <p style="color:black" v-if="translateAktif == 'en'">Hasina also accepts orders for various custom and PO items</p>
          
          <div class="row">
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Seragam+Proyek`" class="mb-0">{{ translateAktif == 'id' ? 'Seragam Proyek' : 'Project Uniform'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Kameja+Kantor`" class="mb-0">{{ translateAktif == 'id' ? 'Kemeja Kantor' : 'Office Shirts'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Kameja+Sekolah`" class="mb-0">{{ translateAktif == 'id' ? 'Kemeja Sekolah' : 'School Shirts'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Pakaian+Olahraga`" class="mb-0">{{ translateAktif == 'id' ? 'Pakaian Olahraga' : 'Sports clothing'}}</a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Jersey`" class="mb-0">{{ translateAktif == 'id' ? 'Jersey' : 'Jerseys'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Jaket`" class="mb-0">{{ translateAktif == 'id' ? 'Jaket' : 'Jacket'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Sweater`" class="mb-0">{{ translateAktif == 'id' ? 'Sweater' : 'Sweaters'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Topi`" class="mb-0">{{ translateAktif == 'id' ? 'Topi' : 'Hat'}}</a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+PDL`" class="mb-0">{{ translateAktif == 'id' ? 'PDL' : 'PDL'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+PDH`" class="mb-0">{{ translateAktif == 'id' ? 'PDH' : 'PDH'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Kaos`" class="mb-0">{{ translateAktif == 'id' ? 'Kaos' : 'Shirt'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Gamis`" class="mb-0">{{ translateAktif == 'id' ? 'Gamis' : 'Gamis'}}</a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Kerudung`" class="mb-0">{{ translateAktif == 'id' ? 'Kerudung' : 'Veil'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Batik`" class="mb-0">{{ translateAktif == 'id' ? 'Batik' : 'Batik'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Sarung`" class="mb-0">{{ translateAktif == 'id' ? 'Sarung' : 'Sarung'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Sepatu`" class="mb-0">{{ translateAktif == 'id' ? 'Sepatu' : 'Shoe'}}</a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Mug`" class="mb-0">{{ translateAktif == 'id' ? 'Mug' : 'Mug'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Tumbler`" class="mb-0">{{ translateAktif == 'id' ? 'Tumbler' : 'Tumbler'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+Lanyard`" class="mb-0">{{ translateAktif == 'id' ? 'Lanyard' : 'Lanyard'}}</a>
              </div>
            </div>
            <div class="col-lg-3 col-6 pb-1 mb-lg-0">
              <div class="bg-light p-2 text-center h-100">
                <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja`" class="mb-0">{{ translateAktif == 'id' ? 'DLL' : 'DLL'}}</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="d-none space-pb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-6 mb-4 mb-lg-0">
        <div class="bg-light p-4 py-5 text-center h-100">
          <i class="flaticon-contract font-xlll text-primary mb-4"></i>
          <h5 class="mb-3">{{ translateAktif == 'id' ? 'Berpengalaman' : 'Experienced'}}</h5>
          <p class="mb-0">{{ translateAktif == 'id' ? 'Pengalaman kami selama puluhan tahun menunjukan kualitas kami' : 'Our decades of experience show our quality'}}</p>
        </div>
      </div>
      <div class="col-lg-3 col-6 mb-4 mb-lg-0">
        <div class="bg-light p-4 py-5 text-center h-100">
          <i class="flaticon-like font-xlll text-primary mb-4"></i>
          <h5 class="mb-3">{{ translateAktif == 'id' ? 'Berpengalaman' : 'Experienced'}}</h5>
          <p class="mb-0">{{ translateAktif == 'id' ? 'Kualitas produk adalah prioritas kami untuk konsumen' : 'Product quality is our priority for consumers'}}</p>
        </div>
      </div>
      <div class="col-lg-3 col-6 mb-4 mb-sm-0">
        <div class="bg-light p-4 py-5 text-center h-100">
          <i class="flaticon-agent font-xlll text-primary mb-4"></i>
          <h5 class="mb-3">{{ translateAktif == 'id' ? 'Pelayanan Terbaik' : 'Best Service'}}</h5>
          <p class="mb-0">{{ translateAktif == 'id' ? 'Pelayanan terbaik menjadi ciri khas kami' : 'The best service is our hallmark'}}</p>
        </div>
      </div>
      <div class="col-lg-3 col-6 mb-4 mb-sm-0">
        <div class="bg-light p-4 py-5 text-center h-100">
          <i class="flaticon-time-call font-xlll text-primary mb-4"></i>
          <h5 class="mb-3">{{ translateAktif == 'id' ? 'Respon Cepat' : 'Fast Response'}}</h5>
          <p class="mb-0">{{ translateAktif == 'id' ? 'Kapan pun anda butuhkan, kami siap beri tanggapan' : 'Whenever you need, we are ready to respond'}}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="space-pb mt-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center">
            <div class="section-title text-center">
              <h2> {{ translateAktif == 'id' ? 'List Produk' : 'Product List'}}</h2>
              <p>{{ translateAktif == 'id' ? 'Temukan produk yang diinginkan' : 'Find the desired product'}}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="fetching" class="mt-4" id="rounded">
        <div class="loading">
          <div class="loading">
            <div class="loading">
              <div class="loading">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="data.length > 0 && !fetching" class="row">
          <div class="col-md-3 col-6" v-for="(item, index) in data" :key="index">
            <div class="property-item">
              <div v-for="(row, ind) in item.foto" :key="ind" v-bind:id="`list-${index}-${ind}`" :style="`display:${ind === 0 ? 'block' : 'none'}`" class=" property-image bg-overlay-gradient-04">
                <img class="img-fluid" :src="getImage(`assets/produk/${item.path}/${row.image}`)" alt="">
                <div class="property-agent">
                  <div class="property-agent-image">
                    <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${item.name}+(+${item.code}+)`">
                    <!-- <a target="_blank" :href="`https://wa.me/6285740177785?text=Halo+Hasina...+Saya+ingin+belanja+${item.name}+(+${item.code}+)+%0A%0A+ini linknya+%0A%0A+https://hasina.id/#/portofolio/detail/+${item.code})`"> -->
                    <img class="img-fluid" :src="getImage('assets/icon/wa-1.png')" alt="">
                    </a>
                  </div>
                  <div class="property-agent-info">
                    <a class="property-agent-name" href="#">Pesan Sekarang</a>
                  </div>
                </div>
                <div class="property-agent-popup">
                  <!-- <a href="#"><i class="fas fa-camera"></i> {{ item.count }}</a> -->
                  <a disabled style="background-color:grey" v-if="ind === 0 && item.count > 1" class="mr-1"><i class="fas fa-chevron-left"></i></a>
                  <a @click="prevImg(index, ind)" v-if="ind !== 0 && item.count > 1" class="mr-1"><i class="fas fa-chevron-left"></i></a>
                  <a @click="nextImg(index, ind)" v-if="ind < (item.count-1) && item.count > 1"><i class="fas fa-chevron-right"></i></a>
                  <a disabled style="background-color:grey" v-if="ind === (item.count-1) && item.count > 1"><i class="fas fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="property-details">
                <div class="property-details-inner">
                  <h5 class="property-title"><router-link :to="`/portofolio/detail/${item.code}`" >{{ item.name }}</router-link></h5>
                  <span class="property-address"><i class="fas fa-ticket-alt fa-xs"></i>{{ item.code }}</span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div  v-if="data.length === 0 && !fetching" style="background-color:#f8f9fa" class="p-4 text-center empty-state">
          <i style="font-size:50px" class="mb-3 fas fa-folder-open"></i>
          <h5>No Data Available</h5>
          <p>Sorry, there is no data to display at the moment.</p>
      </div>
    </div>
</section>

<section class="d-none space-pb">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title text-center">
          <h2> {{ translateAktif == 'id' ? 'List Produk' : 'Product List'}}</h2>
          <p>{{ translateAktif == 'id' ? 'Temukan produk yang diinginkan' : 'Find the desired product'}}</p>
        </div>
      </div>
    </div>
    <div class="row">
    <div class="col-md-4 mb-lg-0 mb-4">
      <div class="feature-info-03 text-center">
        <div class="feature-info-image bg-overlay-gradient-03 bg-holder" style="background-image: url(../assets/produk/KNV04/varsity-1.webp)">
        </div>
        <div class="feature-info-content">
          <h4 class="mb-3 feature-info-title">{{ translateAktif == 'id' ? 'Pakaian' : 'Clothing'}}</h4>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-lg-0 mb-4">
      <div class="feature-info-03 text-center">
        <div class="feature-info-image bg-overlay-gradient-03 bg-holder" style="background-image: url(../assets/produk/BTK01/type1-1.webp)">
        </div>
        <div class="feature-info-content">
          <h4 class="mb-3 feature-info-title">Batik</h4>
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-lg-0 mb-4">
      <div class="feature-info-03 text-center">
        <div class="feature-info-image bg-overlay-gradient-03 bg-holder" style="background-image: url(../assets/produk/MRC01/mug-1.webp)">
        </div>
        <div class="feature-info-content">
          <h4 class="mb-3 feature-info-title">Merchandise</h4>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>

<section class="testimonial-main bg-holder" style="background-image: url(../templates/default/images/bg/02.jpg);">
   <div class="container">
      <div class="row">
         <div class="col-lg-8">
            <div class="owl-carousel owl-dots-bottom-left owl-loaded owl-drag" data-nav-dots="true" data-items="1" data-md-items="1" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0">
               <div class="owl-stage-outer">
                  <div class="owl-stage" style="transform: translate3d(-1460px, 0px, 0px); transition: all 1s ease 0s; width: 4380px;">
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Hasina terbaik, pengiriman cepat aman dan responsif
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Yono</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Dari 2011, sudah order berulang kali, pelayanan tetap terbaik, dibandingkan dengan konveksi lainnya
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Aji</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item active" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Pokoknya, kalau mau beli batik, di Hasina aja, jgn yg lain!
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Tika</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Sempat ngeborong barang di toko pekalongan, mau borong lagi ah, batik nyaman dipakai
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Bezi</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Sempet bingung mau cari vendor tas dimana, ternyata Hasina bisa ya, tau gitu dari dulu aku order di Hasina, pelayanannya top markotop
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Abdul</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Jos pokoknya, seragam kantor yg dipesan lewat Hasina, kece punya dah
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Ajip</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Ga tahu kenapa, suka aja order di Hasina, banyak varian batik yang  bagus banget
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Yadi</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="owl-item cloned" style="width: 730px;">
                        <div class="item">
                           <div class="testimonial">
                              <div class="testimonial-content">
                                 <p>
                                    <i class="quotes">"</i>Saya sangat berterimakasih atas pelayannya, Hasina professional dan cekatan! Big Thanks buat Hasina
                                 </p>
                              </div>
                              <div class="testimonial-name">
                                 <h6 class="text-primary mb-1">Anisa June</h6>
                                 <!-- <span>- instansi -</span> -->
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="owl-nav disabled">
                  <button type="button" role="presentation" class="owl-prev">
                     <i class="fas fa-chevron-left"></i>
                  </button>
                  <button type="button" role="presentation" class="owl-next">
                     <i class="fas fa-chevron-right"></i>
                  </button>
               </div>
               <div class="owl-dots">
                  <button role="button" class="owl-dot active">
                     <span></span>
                  </button>
                  <button role="button" class="owl-dot">
                     <span></span>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<section class="space-ptb bg-light our-clients">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h2>{{ translateAktif == 'id' ? 'Klien & Mitra' : 'Client & Partner'}}</h2>
                </div>
                <div class="owl-carousel owl-nav-center owl-loaded owl-drag" data-animateout="fadeOut" data-nav-dots="false" data-items="6" data-md-items="5" data-sm-items="4" data-xs-items="3" data-xx-items="2" data-space="50">
                    <div class="owl-stage-outer">
                        <div class="owl-stage" style="transform: translate3d(-1353px, 0px, 0px); transition: all 1s ease 0s; width: 3480px;">
                            <div class="owl-item active" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/bps.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item active" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/cta.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item active" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/hebel.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item active" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/hikam.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item active" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/hipmi.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/lebak.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/sma.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/tax1.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/jamiyah.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/word.webp')" alt="">
                                </div>
                            </div>
                            <div class="owl-item" style="width: 143.333px; margin-right: 50px;">
                                <div class="item">
                                    <img class="img-fluid" :src="getImage('assets/client/kjss.webp')" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="owl-nav disabled">
                        <button type="button" role="presentation" class="owl-prev">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <button type="button" role="presentation" class="owl-next">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <div class="owl-dots disabled"></div>
                </div>
            </div>
        </div>
    </div>
</section>

    </div>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Home',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        data() {
            return {
                translateAktif: this.$root.$translateAktif,
                username: "",
                password: "",
                submitted: false,
                data:[]
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        mounted() {
            this.getLocalStorage();
            this.loadProduct();
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                this.$router.push('/admin/dashboard');
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
            },
            loadProduct() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "protofolio/all", {
                    params: {
                        // code: this.code,
                        code: '',
                    }
                })
                    .then((response) => {
                        this.data = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            getProduct(code) {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "protofolio", {
                    params: {
                        code: code,
                    }
                })
                    .then((response) => {
                        this.data = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            prevImg(index, ind) {
              const fixInd = ind-1;
              document.querySelector("#list-"+index+"-"+ind).style.display = "none";
              document.querySelector("#list-"+index+"-"+fixInd).style.display = "block";
            },
            nextImg(index, ind) {
              const fixInd = ind+1;
              document.querySelector("#list-"+index+"-"+ind).style.display = "none";
              document.querySelector("#list-"+index+"-"+fixInd).style.display = "block";
            }
        }
    }
</script>