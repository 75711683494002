// Configuration API
let host = location.hostname;
const LOCAL_DOMAINS = ["localhost","127.0.0.1","192.168"];
if (LOCAL_DOMAINS.includes(host)) { 
    // host = location.protocol+"//"+location.host+"/";
    host = "http://localhost:8080/";
} else {
    // var domain = location.hostname;
    // var subdomain = domain.split('.')[0];
    // var domain_rest = domain.replace(subdomain, "");
    host = "https://api.hasina.id/";
    // host = location.protocol+"//api-"+subdomain+""+domain_rest+"/";
}
export default {
    host : host
};
