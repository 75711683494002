import Vue  from 'vue';
import App  from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
// import VueAxios from 'vue-axios';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import VModal from 'vue-js-modal/dist/index.nocss';
import configuration from './configuration';
import 'vue-js-modal/dist/styles.css';
import CKEditor from 'ckeditor4-vue';
// MARKDOWN COMPONENT
import VueSimplemde from 'vue-simplemde';
import 'simplemde/dist/simplemde.min.css';

// EXCEL
import JsonExcel from "vue-json-excel";

// Markdown View
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

import VuePageTransition from 'vue-page-transition';

Vue.prototype.$translateAktif = 'id';
// Vue Global Variabel
Vue.config.productionTip          = false;
Vue.prototype.$http               = axios;
Vue.prototype.$apiconfig          = configuration.host;
Vue.prototype.$api_upload_engine  = 'https://uploader.dreisystem.id/upload';
Vue.prototype.$translateAktif     = 'in';

// Vue Use
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VuePageTransition);

// Vue.use(VueAxios, axios);
Vue.use(VModal)
Vue.use(CKEditor);
Vue.component('vue-simplemde', VueSimplemde);
Vue.component('markdown-it-vue', MarkdownItVue);
// Vue.component('vue-markdown', VueMarkdown);

// EXCEL
Vue.component("downloadExcel", JsonExcel);

// Add Global Auth Token
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

console.log(process.env.VUE_APP_ENVIRONMENT);