import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
// import configuration from '../configuration';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
        
    }
});