<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">

                <div class="d-block">
                    <div class="d-flex mt-4 p-3 alert alert-success text-dark rounded align-items-center">
                        <div class="mr-3"><img src="https://kdo.e-planningbmd.id/assets/images/monitor.svg" width="60"></div>
                        <div>
                            <h4 style="color:black !important" class="text-bold text-dark mb-2">Sistem Informasi Persediaan</h4>
                            <p class="mb-2">layanan sistem informasi Persediaan Badan Anggaran dan Aset Daerah</p>
                            <p class="text-sm m-0">Periode : <a class="text-primary">2023 </a></p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-12">
                <div class="alert alert-warning alert-bordered pd-y-15" role="alert">
                    <div class="d-sm-flex align-items-center justify-content-start">
                    <i class="icon ion-alert-circled alert-icon tx-52 tx-warning mg-r-20"></i>
                    <div class="mg-t-20 mg-sm-t-0">
                        <h5 class="mg-b-2 tx-warning">Perhatian !!!</h5>
                        <p class="mg-b-0 tx-gray">- Pastikan semua SKPD sudah menambahkan master bidang/bagian pada menu <b >
                       <router-link to="/general/profile">master -> bidang</router-link>
                    </b> </p>
                        <p class="mg-b-0 tx-gray">- Sistem validasi data real akan segera di pasang, diharapakan data yang sifatnya masih percobaan agar segera di hapus 🙏🏻</p>
                    </div>
                    </div>
                </div>

                <div class="alert alert-danger" role="alert">
                   Untuk memeperbaharui Profil silahkan !!! Klik 
                   <b>
                       <router-link to="/general/profile">link</router-link>
                    </b> 
                   berikut 
                </div>
            </div>

            <div class="col-sm-8 mb-3">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Data Pengguna</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pengguna | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Data Satuan</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.satuan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Kode Persediaan</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.persediaan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Usulan Barang</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.usulan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border:1px solid gray">
                <!-- TRANSAKSI MASUK -->
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Saldo Awal</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.saldo | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.saldo_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Pembelian</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pembelian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pembelian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Hibah Masuk</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.hibah_masuk | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.hibah_masuk_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TRANSAKSI KELUAR -->
                <div class="row mt-3">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Pemakaian</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pemakaian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pemakaian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Hibah Keluar</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.hibah_keluar | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.hibah_keluar_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 mb-3">
                <div class="card">
                    <label class="text-center mt-2"><b>Input Saldo Awal 2023</b></label>
                    <div class="card-header">
                        <div class="input-group input-group-flush input-group-merge">
                            <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Item...">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="m-2 table table-sm table-striped  table-responsive-sm">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>SKPD</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="fetching">
                                <tr>
                                    <td colspan="3">
                                        <div class="alert alert-primary" role="alert">
                                            Sedang mengambil data..
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="!fetching">
                                <tr v-for="(user, index) in dataPengguna" :key="index">
                                    <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                    <td>{{ user.nickname }}</td>
                                    <td>
                                        <span v-if="user.status == 'belum'" class="badge badge-danger">BELUM</span>
                                        <span v-if="user.status == 'sudah'" class="badge badge-success">SUDAH</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer border-top-0">
                        <div style="float:right">
                            <!-- <ul class="pagination mb-0"></ul> -->
                            <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                        :page-count="pagination.totalPages"
                                        :classes="pagination.bootstrapPaginationClasses"
                                        :labels="pagination.paginationAnchorTexts"
                                        v-on:change="getData()"></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vPagination from 'vue-plain-pagination'
    import { mapState } from 'vuex'
    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Dashboard'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                currencyMask,
                dataPengguna: [],
                statistik: {
                    pengguna: 0,
                    persediaan: 0,
                    satuan: 0,
                    usulan: 0,
                    saldo: 0,
                    pembelian: 0,
                    hibah_masuk: 0,
                    pemakaian: 0,
                    hibah_keluar: 0,
                    saldo_rp: 0,
                    pembelian_rp: 0,
                    hibah_masuk_rp: 0,
                    pemakaian_rp: 0,
                    hibah_keluar_rp: 0,
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
            }
        },
        created() {
            this.getData();
            this.getStatistik();
        },
        components: {
            vPagination
        },
        computed: mapState(['userdata', 'school_info']),
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/beranda', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getStatistik() {
                this.$http.get(this.baseUrl + `admin/beranda/statistik`)
                    .then((response) => {
                        this.statistik = response.data.data;
                    })
                    .catch(() => {
                        this.fetching = false;
                    });
            },
        },
    };
</script>