import Vue from 'vue';
import VueRouter from 'vue-router';
import Display from '@/views/general/index.vue';
import VueMeta from 'vue-meta';
// import Axios from 'axios';
// import configuration from './configuration';
import VueBodyClass from 'vue-body-class';

import Home from '@/views/admin/dashboard/Dashboard.vue';
import SideBar from '@/layouts/SideBar.vue';
import MainWebsite from '@/layouts/MainWebsite.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: Home,
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas']
        }
    },
    {
        path: '/admin/master/pengguna',
        name: 'master.pengguna',
        component: () => import('@/views/admin/master/MasterPengguna.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },

    /* -------------------------------------------------------------- */
    {
        path: '/',
        name: 'display',
        component: Display,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Display,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/about_us',
        name: 'about_us',
        component: () => import('./views/component/about/about_us.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/why_us',
        name: 'why_us',
        component: () => import('./views/component/about/why_us.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/portofolio/:id',
        name: 'portofolio',
        component: () => import('./views/component/portofolio/portofolio.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/portofolio/detail/:id',
        name: 'portofolio.detail',
        component: () => import('./views/component/portofolio/detail.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/produk/detail',
        name: 'produk.detail',
        component: () => import('./views/component/produk/detail.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('./views/component/contact/contact.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('./views/component/order/order.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/admin/authentication/Signout.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound,
    },      
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    vueBodyClass.guard(to, next);
});

export default router;